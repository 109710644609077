import { render, staticRenderFns } from "./ModifyUserGroupCustom.vue?vue&type=template&id=542c6730&scoped=true&"
import script from "./ModifyUserGroupCustom.vue?vue&type=script&lang=ts&"
export * from "./ModifyUserGroupCustom.vue?vue&type=script&lang=ts&"
import style0 from "./ModifyUserGroupCustom.vue?vue&type=style&index=0&id=542c6730&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "542c6730",
  null
  
)

export default component.exports